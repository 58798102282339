import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// React boostrap
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
//SEO Matter
import Seo from "../components/seo"
const IndexPage = () => (
  <div id="Index">
    <Seo
      title="Fahrradanlehnbügel | Fahrradständer | Fahrradunterstände - BIKESAFE"
      description="Bike Safe ist offizieller Lieferant des Radwegprogramms Barclays Cycle Superhighway der Londoner Verkehrsbehörde 'Transport for London'. Dafür hat Bike Safe alle vier bestehenden Routen mit Fahrradständern, Fahrradparkern und Fahrradunterständen ausgestattet."
    />
    <Layout>
      <Container fluid className="p-0">
        <Row className="mb-5">
          <Col className="text-center no-padding-or-margin">
            <div className="parallax-home"><div className="home-title"><h1 className="home-title-text">
              Bikesafe</h1>
              <h1 className="home-title-text-under opacity">
              Fahrradparksysteme</h1></div></div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Fahrradanlehnbügel</h1>
          </Col>
        </Row>
        <Row>
        <Col sm="12" lg="3" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Doppelstock-Fahrradparker</h3>
              <p>Platzsparend, großartige Benutzerfreundlichkeit und ausgezeichnetes Preis-Leistungs-Verhältnis! Der Doppelstock-Fahrradparker ist ein sehr innovativer und benutzerfreundlicher Fahrradständer...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Doppelstock-Fahrradparker</h3>
              <p>Der BikeSafe Two Tier Bike Rack ist ein wegweisendes und benutzerfreundliches Fahrradparksystem mit ausgezeichneter platzsparender Fahrradaufbewahrung, hervorragender Benutzerfreundlichkeit und...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Zweiseitiger vertikaler Fahrradparker</h3>
              <p>Mit diesem Produkt können die Nutzer ihre verfügbare Bodenfläche maximal nutzen. Der Rahmen kann überall im Raum sicher am Boden verschraubt werden. Ideal für Büros, Wohnobjekte oder Schulen...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Semi-vertikaler Fahrradparker</h3>
              <p>Optimiert die Nutzung des vorhandenen Platzes, wo dieser knapp bemessen ist. Der semi-vertikale Parker kann sowohl im Innen- als auch im Außenbereich genutzt werden. Das Hinterrad kann mithilfe...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Fahrradständer</h1>
          </Col>
        </Row>
        <Row>
        <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Traditioneller Fahrradanlehnbügel Münster</h3>
              <p>Das klassische und beliebte Design ist perfekt für alle diejenigen, die auf der Suche nach minimalistischen Fahrradparkmöglichkeiten mit tollem Preis-Leistungs-Verhältnis und geringem Wartungsaufwand sind.</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Traditioneller Fahrradanlehnbügel Münster (Schwarz)</h3>
              <p>Das klassische und beliebte Design ist perfekt für alle diejenigen, die auf der Suche nach minimalistischen Fahrradparkmöglichkeiten mit tollem Preis-Leistungs-Verhältnis und geringem Wartungsaufwand sind.</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
      <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Fahrradunterstände</h1>
          </Col>
        </Row>
                <Row>
                <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand New York</h3>
                            <p>Unterstand verzinkt oder mit Pulverbeschichtung in RAL-Farben erhältlich und inklusive verzinktem Reihenanlehnbügel. Übersteigsicheres Dach und Seitenrahmen mit 5 mm starken Polycarbonatplatten.</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand Arche</h3>
                            <p>Dieser Unterstand wurde entwickelt, um die doppelt so viele Fahrräder auf relativ geringem Raum in 2 Reihen mit Fahrradstellplätzen unterzubringen. Unterstand verzinkt oder mit Pulverbeschichtung...</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand Dresden</h3>
                            <p>Ein Unterstand, der sich dank vielseitigen Merkmalen perfekt für jedes Umfeld eignet. Unterstand aus Baustahl zum Aufschrauben. Unterstand verzinkt oder mit Polyester-Pulverbeschichtung in RAL-Farben...</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Bikesafe - Fahrradparksysteme</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="p-2">
            <p>Bike Safe ist offizieller Lieferant des Radwegprogramms Barclays Cycle Superhighway der Londoner Verkehrsbehörde 'Transport for London'. Dafür hat Bike Safe alle vier bestehenden Routen mit Fahrradständern, Fahrradparkern und Fahrradunterständen ausgestattet.</p>
            <p>Die Barclays Cycle Superhighways sind neue Fahrradrouten, die aus den Außenbezirken Londons ins Stadtzentrum führen, um Fahrradfahrern sichere, schnellere und direktere Strecken zu bieten.</p>
            <p>Barclays Cycle Superhighways wird mithilfe von Bike Safe, dem britischen Spezialanbieter für Fahrradparksysteme, tausende neue Parkplätze – sowohl freie als auch subventionierte für das Commuter Cycle Training-Programm – sowie bessere Einrichtungen für Fahrradfahrer am Arbeitsplatz schaffen.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
)

export default IndexPage
